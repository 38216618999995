import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.css';

export default function SignUpFixed() {
  return (
    <div className={`${style.container} d-flex d-sm-none`}>
      <h5 className={`${style.text} d-inline text-left`}>
        Aylık 149 TL’ <span className={`${style.subText}`}>den başlayan fiyatlarla tüm eğitimlere sınırsız erişim</span>
      </h5>
      <div className="d-flex align-items-center mt-1">
        <Link to="/sign-up" className="btn btn-hover d-flex align-items-center" title="Üye Ol">
          Üye Ol
        </Link>
      </div>
    </div>
  );
}
