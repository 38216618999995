import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bgImg from '../../../assets/images/bg/r3.jpg';
import './style.css';
import { useAuth } from '../../../contexts/AuthContext';
import { getPlans, Reg3 } from '../../../Services/AuthApi';
import iconPlus from '../../../assets/icons/IconPlus.png';
import { notifyError } from '../../../components/AlartNotify/AlertNotify';
import { trackEvent } from '../../../libs/pixels';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const SelectPlan = (props) => {
  let history = useHistory();
  const location = useLocation();
  const queryParams = window.location.pathname.includes('mobile');
  const { regId, setSelectedPlan } = useAuth();
  const [isMonthly, setIsMonthly] = useState(false);
  const [price, setPrice] = useState([]);
  const hasCoupone = location.pathname == '/select-plan/wise25' ? true : false;
  const hasAskCoupone = location.pathname == '/select-plan/ask25' ? true : false;

  const notifySuccess = (text) => {
    toast.success(text, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };
  const handleCopy = () => {
    const discountCode = 'WISE25'; // Kopyalanacak metin
    navigator.clipboard
      .writeText(discountCode)
      .then(() => {
        notifySuccess('Kod kopyalandı: ' + discountCode);
      })
      .catch((err) => {
        console.error('Kopyalama başarısız:', err);
      });
  };

  const handleCopyAsk = () => {
    const discountCode = 'WISE25'; // Kopyalanacak metin
    navigator.clipboard
      .writeText(discountCode)
      .then(() => {
        notifySuccess('Kod kopyalandı: ' + discountCode);
      })
      .catch((err) => {
        console.error('Kopyalama başarısız:', err);
      });
  };


  useEffect(() => {
    trackEvent('conversion_event_begin_checkout_5', {});
    getPlans('Yearly')
      .then((res) => {
        setPrice(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => { };
  }, []);

  const onSubmit = (type) => {
    Reg3(regId?.id, { plan_id: type })
      .then((data) => {
        //notify('Başarı İle Oluşturuldu')
        localStorage.setItem('selectedPlan', isMonthly);
        if (queryParams) history.push('/mobile/set-card');
        else if (hasCoupone) history.push('/set-card/wise25');
        else if (hasAskCoupone) history.push('/set-card/ask25');
        else history.push('/set-card');
      })
      .catch((err) => {
        notifyError('Error');
      });
    //  history.push('/set-card')
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section
        className="sign-in-page slick-bg ws-bg-select-plan"
        style={{
          backgroundImage: `url(${bgImg})`,
          position: 'fixed',
          height: '100%',
          position: 'relative',
        }}
      >
        <Container>
          <Row className="justify-content-center align-items-center plan-visible" style={{ marginTop: '5%' }}>
            <Col lg="10" md="12" className="align-self-center">
              <div className="text-center ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    {hasCoupone && (
                      <div className="btn btn-success  text-center mb-3  cursor-pointer " onClick={handleCopy}>
                        {' '}
                        <strong className="text-white">
                          %25 İNDİRİM KODU: <span className="h5">WISE25</span>
                        </strong>{' '}
                      </div>
                    )}
                    {hasAskCoupone && (
                      <div className="btn btn-success  text-center mb-3 w-100 cursor-pointer " onClick={handleCopyAsk}>
                        {' '}
                        <strong className="text-white">
                          %25 İNDİRİM KODU: <span className="h5">ASK25</span>
                        </strong>{' '}
                      </div>
                    )}
                    <h6 className="mb-3 text-primary text-center">Adım 3/4</h6>
                    <h1 className="d-none d-sm-block h2 mb-3 text-center">Üyelik Planını Seç</h1>
                    <h1 className="d-block d-sm-none h3 mb-3 text-center">Üyelik Planını Seç</h1>
                    <div>(Yıllık)</div>
                    <div className="isMonthly">
                      <div
                        className={`${!isMonthly && 'isMonthlyActive'}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsMonthly(false);
                        }}
                      >
                        Taksitli
                      </div>
                      <div
                        className={`${isMonthly && 'isMonthlyActive'}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsMonthly(true);
                        }}
                      >
                        Peşin
                      </div>
                    </div>
                  </div>
                  <Row className="mt-2 pb-5 justify-content-center">
                    <Col sm="12" md="6" className="p-0 pt-4 pb-4">
                      <div className="ws-card-left text-left">
                        <div className="text-primary text-uppercase my-3">
                          {isMonthly ? 'Peşin' : 'Taksitli'} <span className="text-capitalize">(Yıllık)</span>
                        </div>
                        <div className="text-white my-3">1 Hesap - 4 Profil</div>
                        <h4 className="my4">
                          {/* {!inLogin && (isMonthly ? monthlyPrice[0]?.price : yearPrice[0]?.price)} */}
                          {isMonthly ? price[3]?.name : price[2]?.name} <span>{!isMonthly && '(Toplam: ' + price[2]?.price + ' TL)'}</span>
                          {/* <span style={{ fontSize: '25px', color: '#fff' }}>₺ {!isMonthly ? '/Ay' : ''}</span> */}
                        </h4>
                        <div className="my-3  d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-light">9 Kategori+500’den Fazla Ders</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Ay Yeni İçerikler</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Ay Size Özel Bonus İçerikler</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Bilgisayardan, Mobil Cihazdan, Tabletten Sınırsız Erişim</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">İzlerken Not Alma Özelliği</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Mobil Cihazdan Podcast Dinleme Özelliği</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Eğitimle Yeni Bir Sertifika Kazanma İmkânı</span>
                        </div>

                        {/* <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">İstediğin Zaman İptal Etme Seçeneği</span>
                        </div> */}
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">12 Ay Sınırsız Erişim İmkanı</span>
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center mt-3 w-100"
                          data-iq-gsap="onStart"
                          data-iq-position-y="80"
                          data-iq-delay="0.8"
                        >
                          <div
                            className="btn btn-hover w-100"
                            onClick={() => {
                              setSelectedPlan(isMonthly ? price[1] : price[2]);
                              onSubmit(isMonthly ? price[1]?.id : price[2]?.id);
                            }}
                          >
                            SATIN AL
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SelectPlan;
