import React, { useState, useEffect, createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getCategories } from '../Services/Category';
import { getNotifications } from '../Services/Notificatio';

const RequestContext = createContext({});

const RequestProvider = ({ children }) => {
  const [videosList, setVidios] = useState([]);
  const [teacher, setTeacher] = useState(JSON.parse(localStorage.getItem('ws-video-teacher')) || '');
  const [refresh, setRefresh] = useState(false);
  const [notRefresh, setNotRefresh] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState(JSON.parse(localStorage.getItem('ws-select-category')) || '');
  const [selectCategoryTeacher, setSelectCategoryTeacher] = useState(JSON.parse(localStorage.getItem('ws-select-category-teacher')) || '');
  const [notifications, setSotifications] = useState(null);
  useEffect(() => {
    getCategories()
      .then((data) => {
        setCategories(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    let v1 = JSON.parse(localStorage.getItem('ws-video-list')) || '';
    setVidios(v1);
    if (!localStorage.getItem('ws-video-teacher')) {
      let t1 = JSON.parse(localStorage.getItem('ws-video-teacher')) || '';
      setTeacher(t1);
    }

    return () => { };
  }, [refresh]);
  useEffect(() => {
    if (localStorage.getItem('ws-user-data')) {
      getNotifications()
        .then((res) => {
          setSotifications(res?.data);
        })
        .catch(() => { });
    }

    return () => { };
  }, [notRefresh]);

  const videoSet = (items) => {
    localStorage.setItem('ws-video-list', JSON.stringify(items));
    setVidios(items);
  };
  const teacherSet = (item) => {
    localStorage.setItem('ws-video-teacher', JSON.stringify(item));
    setTeacher(item);
  };
  const Refresh = () => {
    setRefresh(!refresh);
  };
  const NotRefresh = () => {
    setNotRefresh(!notRefresh);
  };
  const setCat = (category) => {
    // window.location.pathname = '/'+category.slug;
    // console.log(window.location.pathname)
    localStorage.setItem('ws-select-category', JSON.stringify(category));
    setSelectCategory(category);
  };
  const setCatTeacher = (id) => {
    localStorage.setItem('ws-select-category-teacher', JSON.stringify(id));
    setSelectCategoryTeacher(id);
  };

  const setVideoCurentTime = (slug) => {
    console.log(videosList);
    console.log(slug);
  };

  const values = {
    videosList,
    videoSet,
    categories,
    teacherSet,
    teacher,
    Refresh,
    refresh,
    setCat,
    selectCategory,
    setCatTeacher,
    selectCategoryTeacher,
    NotRefresh,
    notifications,
    setVideoCurentTime,
  };

  return <RequestContext.Provider value={values}>{children}</RequestContext.Provider>;
};

const useApi = () => useContext(RequestContext);

export { RequestProvider, useApi };
