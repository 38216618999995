import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import SliderCW from '../../components/ContinuesWatching/SliderCW';
import AllClasses from './components/AllClasses';
import MyNotesSlider from '../../components/MyNotes/NotesSlider';
import MyCertificates from '../../components/MyCertificates';
import { useAuth } from '../../contexts/AuthContext';
import LikedTeachersSlider from '../../components/TeachersSlider/LikedSlideTeacher';
import EditProfile from '../CreateProfile/EditProfile';

export default function MyProgress() {
  const { profile, user } = useAuth();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: '#000' }}>
          <EditProfile profile={profile} handleClose={handleClose} isSetting={true} />
        </Modal.Body>
      </Modal>
      <div className="iq-breadcrumb-one-profile  iq-bg-over iq-over-dark-50" style={{ panding: '0px 0 80px' }}>
        {/* <Container>
          <Row>
            <Col sm="2" className=" text-sm-start text-md-right">
              <div className="upload_profile d-inline-block text-xs-center">
                <img src={profile?.avatar?.path} className="profile-pic avatar-120 rounded img-fluid" alt="user" />
              </div>
            </Col>
            <Col sm="5" className="d-flex align-items-center">
              <div className="w-100">
                <h3 className="text-center text-md-left"> {profile?.name}</h3>
              </div>
            </Col>
            <Col sm="5" className="d-flex align-items-center">
              <div className="w-100 mt-4 text-sm-start text-md-right">
                <div onClick={handleShow} className="btn btn-hover ">
                  Profili Düzenle
                </div>
              </div>
            </Col>
          </Row>
        </Container> */}
      </div>
      <section id="iq-tvthrillers" className="s-margin mb-5">
        <Container>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <div className="d-flex align-items-center justify-content-between mb-5">
                <h4 className="main-title channel-logo w-100 text-left mt-0">İzlemeye Devam Et</h4>
                <div className="d-flex align-items-center justify-content-between">
                  <div style={{ width: '50px' }} className="d-flex align-items-center justify-content-between mr-3">
                    <div id="prevChoices" className="">
                      <i className="fa fa-chevron-left"></i>
                    </div>
                    <div id="nextChoices" className="">
                      <i className="fa fa-chevron-right"></i>
                    </div>
                  </div>

                  {/* <Link className="iq-view-all" to="/movie-category">
                      Tümünü Gör
                    </Link> */}
                </div>
              </div>
              <SliderCW prev="prevChoices" next="nextChoices" query={'watching'} />
            </Col>
          </Row>
        </Container>
      </section>

      <AllClasses />
      {user?.roles?.filter((item) => item?.name == 'Member').length > 0 ? (
        ''
      ) : (
        <section id="iq-tvthrillers" className="s-margin mb-5">
          <Container>
            <Row>
              <Col sm="12" className="overflow-hidden">
                <div className="d-flex align-items-center justify-content-between mb-5">
                  <h4 className="main-title channel-logo w-100 text-left mt-0">Atanmış Eğitimler</h4>
                  <div className="d-flex align-items-center justify-content-between">
                    <div style={{ width: '50px' }} className="d-flex align-items-center justify-content-between mr-3">
                      <div id="prevChoices" className="">
                        <i className="fa fa-chevron-left"></i>
                      </div>
                      <div id="nextChoices" className="">
                        <i className="fa fa-chevron-right"></i>
                      </div>
                    </div>

                    {/* <Link className="iq-view-all" to="/movie-category">
                      Tümünü Gör
                    </Link> */}
                  </div>
                </div>
                <SliderCW prev="prevChoices" next="nextChoices" query={'assigned-classrooms'} />
              </Col>
            </Row>
          </Container>
        </section>
      )}

      <section>
        <MyNotesSlider />
      </section>
      <section id="iq-favorites" className="mt-5">
        <LikedTeachersSlider link="/following-teachers" />
      </section>
      <section>
        <MyCertificates />
      </section>
    </div>
  );
}
