import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import bgImg from '../../../assets/images/bg/r3.jpg';
import './style.css';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Reg4, checkCardNumber, checkCouponeCode } from '../../../Services/AuthApi';
import { useAuth } from '../../../contexts/AuthContext';
import { notify, notifyError } from '../../../components/AlartNotify/AlertNotify';
import { fbEvent, linkedinEvent, trackEvent, trackTikTokEvent } from '../../../libs/pixels';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const CardSchema = Yup.object().shape({
  card_name: Yup.string()
    .min(2, 'En az 3 karakter olmalıdır')
    .max(50, 'En fazla 50 karakter olmalıdır')
    .required('İsim zorunlu bir alandır!')
    .matches(/^[A-Za-z\u00C0-\u00FF\u011E\u011F\u0130\u0131\u015E\u015F\u00DC\u00FC_\s]+$/, 'Sadece harf girilebilir'),
  card_surname: Yup.string()
    .min(2, 'En az 3 karakter olmalıdır')
    .max(50, 'En fazla 50 karakter olmalıdır')
    .required('Soyisim zorunlu bir alandır!')
    .matches(/^[A-Za-z\u00C0-\u00FF\u011E\u011F\u0130\u0131\u015E\u015F\u00DC\u00FC_\s]+$/, 'Sadece harf girilebilir'),
  card_number: Yup.string().required('Kart numarası zorunlu bir alandır!'),
  card_date: Yup.string()
    .typeError('Geçerli bir son kullanma tarihi değil. Örnek: AA/YY')
    .max(5, 'Geçerli bir son kullanma tarihi değil. Örnek: AA/YY')
    .matches(/([0-9]{2})\/([0-9]{2})/, 'Geçerli bir son kullanma tarihi değil. Örnek: AA/YY')
    .required('Son kullanım tarihi zorunlu bir alandır!'),
  card_code: Yup.number().required('CVV zorunlu bir alandır!'),
  discount_code: Yup.string(),
});

const initialValues = {
  card_name: '',
  card_surname: '',
  card_number: '',
  card_date: '',
  card_code: '',
  discount_code: '',
};

const SetBankCard = (props) => {
  let history = useHistory();
  const location = useLocation();
  const queryParams = window.location.pathname.includes('mobile');
  const [loading, setLoading] = useState(false);
  const [val, setVal] = useState('');
  const [valDate, setValDate] = useState('');
  const [installment, setInstallment] = useState(12);
  const { regId, selectedPlan } = useAuth();
  const [couponeCode, setCouponeCode] = useState('');
  const [hasCode, setHasCode] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [selectedPlanPlaceholder, setSelectedPlaceholder] = useState(selectedPlan?.name);
  const [newPrice, setNewPrice] = useState(0)
  const [accept, setAccept] = useState(false);
  const hasCoupone = location.pathname == '/set-card/wise25' ? true : false;
  const hasAskCoupone = location.pathname == '/set-card/ask25' ? true : false;

  const onChange = (e) => {
    let code = document.getElementById('exampleInputcard_number').value;
    let card_date = document.getElementById('card_date').value;
    setVal(code);
    setValDate(card_date);
  };

  const handleCopy = () => {
    const discountCode = 'WISE25'; // Kopyalanacak metin
    navigator.clipboard
      .writeText(discountCode)
      .then(() => {
        notify('Kod kopyalandı: ' + discountCode);
      })
      .catch((err) => {
        console.error('Kopyalama başarısız:', err);
      });
  };
  const handleCopyAsk = () => {
    const discountCode = 'ASK25'; // Kopyalanacak metin
    navigator.clipboard
      .writeText(discountCode)
      .then(() => {
        notify('Kod kopyalandı: ' + discountCode);
      })
      .catch((err) => {
        console.error('Kopyalama başarısız:', err);
      });
  };

  const handleDiscountCode = () => {
    //setSelectedPlaceholder((Number(selectedPlanPlaceholder) * 25) / 100)
    setNewPrice((Number(selectedPlan?.price) - (Number(selectedPlan?.price) * 25) / 100).toFixed(0) + ' TL')
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CardSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      let body = {
        cardholder_name: values.card_name,
        cardholder_surname: values.card_surname,
        card_number: values.card_number.replaceAll(' - ', ''),
        expiration_month: values.card_date.split('/')[0],
        expiration_year: `20${values.card_date.split('/')[1]}`,
        cvc: values.card_code,
        discount_code: couponeCode,
        installment: installment,
      };

      Reg4(regId?.id, body)
        .then((data) => {
          notify('Başarı İle Oluşturuldu');
          fbEvent('CompleteRegistration', {});
          fbEvent('InitiateCheckout', {});
          trackEvent('sign_up', {
            method: 'email',
          });
          trackTikTokEvent('CompleteRegistration', {});
          trackTikTokEvent('InitiateCheckout', {});
          linkedinEvent('track', { conversion_id: 21153209 });
          if (queryParams) history.push('/mobile/success');
          else history.push('/login');
          setLoading(false);
        })
        .catch((err) => {
          notifyError(err.response.data.error);
          notifyError(err.response.data.message);
          setLoading(false);
        });

      // history.push("/login");
      // notify('Başarı İle Oluşturuldu')
    },
  });
  const handleCouponeCode = (e) => {
    setCouponeCode(e.target.value);
    const body = {
      code: e.target.value,
    };
    checkCouponeCode(body, selectedPlan?.id).then((res) => {
      if (res?.data != null) {
        setSelectedPlaceholder(res?.data?.new_price)

      }
      else {
        setSelectedPlaceholder(selectedPlan?.description)
      }
      setHasCode(res?.success);
      if (res?.success && e.target.value == 'ASK25') {
        setDiscountCode(true)
        handleDiscountCode()
      } else {
        setDiscountCode(false)
        setNewPrice(0)
      }
    });
  };
  const handleCardNumber = (e) => {
    setValDate(e.target.value);
    if (e.target.value.length > 6) {
      checkCardNumber(e.target.value.replaceAll(' - ', '').substring(0, 6), selectedPlan?.id).then((res) => {
        setSelectedPlaceholder(res?.description);
        setInstallment(res?.max_installment);
      });
    }
  };
  useEffect(() => {
    trackEvent('conversion_event_begin_checkout_6', {});
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className="sign-in-page slick-bg ws-bg-credit-card" style={{ backgroundImage: `url(${bgImg})` }} key="Payment">
        <Container className="">
          <Row className="justify-content-center align-items-center ">
            <Col xs="12" md="10" className="align-self-center text-left">
              <div className="sign-user_card ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    {hasCoupone && (
                      <div className="btn btn-success  text-center mb-3 w-100 cursor-pointer " onClick={handleCopy}>
                        {' '}
                        <strong className="text-white">
                          %25 İNDİRİM KODU: <span className="h5">WISE25</span>
                        </strong>{' '}
                      </div>
                    )}
                    {discountCode || hasAskCoupone && (
                      <div className="btn btn-success  text-center mb-3 w-100 cursor-pointer" onClick={handleCopyAsk}>
                        {' '}
                        <strong className="text-white">
                          %25 İNDİRİM KODU: <span className="h5">ASK25</span>
                        </strong>{' '}
                      </div>
                    )}
                    <h6 className="mb-3 text-primary text-center">Adım 4/4</h6>
                    <h1 className="h1 mb-3 text-center">Ödeme Yap</h1>
                    <h6 className="mb-3 text-center">Kart bilgilerini gir</h6>
                    <Form onSubmit={formik.handleSubmit} noValidate onChange={onChange}>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label className="text-white mb-2">İsim</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control mb-0"
                              id="exampleInputcard_name"
                              name="card_name"
                              {...formik.getFieldProps('card_name')}
                              placeholder="İsim Gir"
                              autoComplete="off"
                              required
                            />
                            {formik.touched.card_name && formik.errors.card_name && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.card_name}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label className="text-white mb-2">Soyisim</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control mb-0"
                              id="exampleInputcard_surname"
                              name="card_surname"
                              {...formik.getFieldProps('card_surname')}
                              placeholder="Soyisim Gir"
                              autoComplete="off"
                              required
                            />
                            {formik.touched.card_surname && formik.errors.card_surname && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.card_surname}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <Form.Label className="text-white mb-2">Kart Numarası</Form.Label>
                            <Form.Control
                              type="text"
                              name="number"
                              className="form-control mb-0"
                              id="exampleInputcard_number"
                              {...formik.getFieldProps('card_number')}
                              placeholder="0000 - 0000 - 0000 - 0000"
                              autoComplete="off"
                              value={cc_format(val)}
                              required
                              onChange={(e) => {
                                formik.setFieldValue('card_number', cc_format(e.target.value));
                                handleCardNumber(e);
                              }}
                            />
                            {installment < 12 ? (
                              <div className="fv-plugins-message-container">
                                <span className="text-success" role="alert">
                                  {`Bankanız bu kart ile 12 taksite izin vermemektedir. Banka üst sınırı olan ${installment}  taksit ile ödeyebilirsiniz.`}
                                </span>
                              </div>
                            ) : formik.touched.card_number && formik.errors.card_number ? (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.card_number}
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label className="text-white mb-2">Son Kullanım Tarihi</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control mb-0"
                              id="card_date"
                              pattern="\d\d/\d\d"
                              {...formik.getFieldProps('card_date')}
                              name="card_date"
                              placeholder="MM/YY"
                              value={expriy_format(valDate)}
                              autoComplete="off"
                              maxLength={5}
                              required
                            />
                            {formik.touched.card_date && formik.errors.card_date && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.card_date}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label className="text-white mb-2">CVV</Form.Label>
                            <Form.Control
                              type="password"
                              maxLength={3}
                              className="form-control mb-0"
                              id="exampleInputcard_code"
                              name="card_code"
                              placeholder="***"
                              {...formik.getFieldProps('card_code')}
                              autoComplete="off"
                              required
                            />
                            {formik.touched.card_code && formik.errors.card_code && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.card_code}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm="12">
                          <Form.Group>
                            <Form.Label className="text-white mb-2">Seçtiğin Plan</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control mb-0"
                              id="exampleInputcard_type"
                              name="card_plan"
                              value={newPrice != 0 ? newPrice : selectedPlanPlaceholder}
                              autoComplete="off"
                              required
                              disabled
                            />
                          </Form.Group>
                        </Col>
                        <Col sm="12">
                          <Form.Group className="kod">
                            <Form.Label className="text-white mb-2">Kupon Kodu</Form.Label>
                            <Form.Control
                              type="text"
                              className={`form-control  mb-0 ${couponeCode?.length > 0 ? (hasCode ? 'valid-code' : 'invalid-code') : ''}`}
                              id="exampleInputcard_discount_code"
                              name="discount_code"
                              {...formik.getFieldProps('discount_code')}
                              autoComplete="off"
                              value={couponeCode}
                              onChange={(e) => handleCouponeCode(e)}
                            />
                            {couponeCode?.length > 0 ? (
                              !hasCode ? (
                                <div>
                                  <span className="invalid-code-icon">
                                    <i className="fa fa-exclamation-circle" /> {/* İkonu seçebilirsiniz */}
                                  </span>
                                  <span className="text-primary" role="alert">
                                    Kupon kodu geçersiz
                                  </span>
                                </div>
                              ) : (
                                <span className="valid-code-icon">
                                  <i className="fa fa-check-circle" /> {/* Geçerli kod ikonu */}
                                </span>
                              )
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm="12" className="mt-4 mb-4">
                          <div className="custom-control custom-checkbox d-inline-block">
                            <input type="checkbox" className="custom-control-input pt-1" id="customCheck" onChange={() => setAccept(!accept)} />
                            <label className="custom-control-label pt-1 pb-1 " htmlFor="customCheck">
                              <Link to="/on-bilgilendirme-formu" target="_blank" className="text-primary">
                                Ön Bilgilendirme Formu
                              </Link>{' '}
                              ve{' '}
                              <Link to="/mesafeli-sozlesme" target="_blank" className="text-primary">
                                Mesafeli Sözleşme
                              </Link>
                              'yi kabul ediyorum.
                            </label>
                          </div>
                        </Col>
                        <Col sm="12" className="mt-2">
                          <div className="custom-control custom-checkbox d-inline-block">
                            * Öde ve Şimdi Keşfet butonuna basarak yukarıda yazılı olan miktarı ödeme yükümlülüğü altına gireceksiniz ve üyeliğiniz
                            siz iptal edene kadar yenilenecektir.
                          </div>
                        </Col>
                        <Col sm="12" className="mt-2 mb-3">
                          <div className="custom-control custom-checkbox d-inline-block">
                            * Gelecekte ücret alınmasını istemiyorsanız{' '}
                            <Link to="/mesafeli-sozlesme" target="_blank" className="text-primary">
                              Mesafeli Sözleşme
                            </Link>{' '}
                            saklı kalmak kaydıyla üyeliğinizi dilediğiniz zaman iptal edebilirsiniz.
                          </div>
                        </Col>
                      </Row>

                      <button
                        // onClick={nextStep}
                        type="submit"
                        className="btn btn-hover btn-primary1 my-2 w-100"
                        style={{ textTransform: 'none' }}
                        disabled={!accept || loading}
                      >
                        Öde ve Şimdi Keşfet
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
function cc_format(value) {
  const v = value
    .replace(/\s+/g, '')
    .replace(/[^0-9]/gi, '')
    .substr(0, 16);
  const parts = [];

  for (let i = 0; i < v.length; i += 4) {
    parts.push(v.substr(i, 4));
  }

  return parts.length > 1 ? parts.join(' - ') : value;
}
function expriy_format(value) {
  const expdate = value;
  const expDateFormatter = expdate.replace(/\//g, '').substring(0, 2) + (expdate.length > 2 ? '/' : '') + expdate.replace(/\//g, '').substring(2, 4);

  return expDateFormatter;
}

export default SetBankCard;
